<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :items-per-page="10"
      :server-items-length="totalItems"
      class="elevation-1"
      stle
      :loading-text="$t('table.messages.loading')"
      :loading="loading"
      :search="search"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 15, 30, 50],
        showFirstLastPage: false,
        'items-per-page-text': $t('table.messages.items_per_page'),
      }"
    >
      <template slot="no-data">
        {{ $t('table.messages.no_data') }}
      </template>
      <template v-slot:top>
        <v-container class="pt-5">
          <v-row class="d-flex ml-5">
            <v-col cols="6">
              <v-dialog
                v-model="dialog"
                max-width="700px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="!disableCheckbox"
                    color="forth"
                    dark
                    class="mb-5 pt-0 pl-10 pr-10 white--text d-flex justify-start"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span class="font-weight-bold text-h5">
                      {{ $t('messages.addNewItem') }}
                    </span>
                  </v-btn>
                </template>
                <v-card style="max-height: 450px; overflow: auto">
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-text-field
                          v-model="editedItem.type"
                          outlined
                          :label="$t('table.headers.visaType')"
                          :rules="editedIndex === -1 ? rules : []"
                          validate-on-blur
                        >
                          <template v-slot:label>
                            <div>
                              <span>{{ $t('table.headers.visaType') }}</span>
                              <span
                                v-if="editedIndex === -1 "
                                class="red--text font-weight-bold"
                              > * </span>
                            </div>
                          </template>
                        </v-text-field>
                      </v-row>
                      <v-row>
                        <v-checkbox
                          v-model="editedItem.isCheck"
                          color="#0F56B3"
                          style="margin-top: -3px"
                        >
                          <template v-slot:label>
                            <div
                              class="font-weight-bold"
                              style="color: #464E5F; margin-top: 1px"
                            >
                              {{ $t('table.headers.28hoursManagementRequired') }}
                            </div>
                          </template>
                        </v-checkbox>
                      </v-row>
                      <v-row>
                        <div class="note-text">
                          <span class="mr-2 note-symbol">※</span>各書類をテキストボックス毎にご入力ください
                        </div>
                      </v-row>
                      <v-row
                        v-for="(input, k) in editedItem.documents"
                        :key="k"
                        class="d-flex align-center"
                      >
                        <v-col
                          cols="11"
                          class="pl-0"
                        >
                          <v-text-field
                            v-model="input.name"
                            outlined
                            :hide-details="true"
                            :label="$t('table.headers.documentsRequired')"
                            :rules="editedIndex === -1 && k == 0 ? rules : []"
                            validate-on-blur
                          />
                        </v-col>
                        <v-col cols="1">
                          <div class="d-flex align-center justify-space-around">
                            <v-icon
                              v-show="k == editedItem.documents.length - 1"
                              class="fas fa-plus-circle"
                              color="#0F56B3"
                              size="30px"
                              @click="addDocumentsRequired(k)"
                            >
                              mdi-plus-circle
                            </v-icon>
                            <v-icon
                              v-show="k || ( !k && editedItem.documents.length > 1)"
                              class="fas fa-minus-circle"
                              size="30px"
                              @click="removeDocumentsRequired(input, k)"
                            >
                              mdi-close-circle
                            </v-icon>
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-row class="d-flex justify-end mb-3">
                      <v-col cols="3">
                        <v-btn
                          block
                          color="primary"
                          @click="close"
                        >
                          <span class="text-h5 font-weight-bold"> {{ $t('messages.cancel') }}</span>
                        </v-btn>
                      </v-col>
                      <v-col cols="2">
                        <v-btn
                          block
                          color="primary"
                          type="submit"
                          :disabled="disableCreateBtn()"
                          @click="save"
                        >
                          <span class="text-h5 font-weight-bold">{{ $t('messages.save') }}</span>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col cols="6">
              <v-row>
                <v-col cols="8">
                  <div class="align-self-center">
                    <v-text-field
                      id="search"
                      v-model="search"
                      name="search pa-0"
                      :placeholder="$t('placeholder.visaType')"
                      outlined
                    />
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-dialog
            v-model="dialogDelete"
            max-width="500px"
          >
            <v-card>
              <v-card-title class="font-weight-bold text-h4">
                <v-row class="d-flex align-center justify-center">
                  <v-col cols="1">
                    <v-icon
                      large
                      color="blue darken-2"
                    >
                      mdi-delete-outline
                    </v-icon>
                  </v-col>
                  <v-col cols="8">
                    この操作は元に戻りません。
                    本当に削除してよろしいですか？
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-actions>
                <v-spacer />

                <v-btn
                  class="text-h5"
                  dark
                  color="#E43E08"
                  @click="deleteItemConfirm"
                >
                  {{ $t('messages.delete') }}
                </v-btn>
                <v-btn
                  class="text-h5"
                  @click="closeDelete"
                >
                  {{ $t('messages.cancel') }}
                </v-btn>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
      </template>
      <template v-slot:item.index="props">
        {{ items.indexOf(props.item) + 1 }}
      </template>
      <template
        v-slot:item.documentsRequired="{item}"
        no-gutter
      >
        <v-row style="text-align: left">
          <v-col
            v-for="(prop, index) in item.documents"
            :key="index"
            cols="12"
            sm="6"
          >
            <span>{{ index + 1 }}{{ '.' }} {{ prop.name }}</span>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.28hoursManagement="{ item }">
        <v-simple-checkbox
          v-model="item.isCheck"
          color="#0F56B3"
          :disabled="disableCheckbox"
          @click="onTickCheckbox(item)"
        />
      </template>
      <template
        v-if="!disableCheckbox"
        v-slot:item.actions="{ item }"
      >
        <v-icon
          color="#5CA6D2"
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil-circle
        </v-icon>
        <v-icon
          dark
          color="#C5DCFA"
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('messages.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  import { get } from 'vuex-pathify'
  export default {
    name: 'VisaManager',
    data () {
      return {
        headers: [
          {
            text: this.$t('table.headers.index'),
            align: 'center',
            value: 'index',
            class: 'font-weight-bold',
            width: '10%',
            sortable: false,
          },
          {
            text: this.$t('table.headers.visaType'),
            align: 'start',
            value: 'type',
            class: 'font-weight-bold',
            width: '20%',
            sortable: false,
          },
          {
            text: this.$t('table.headers.documentsRequired'),
            align: 'center',
            value: 'documentsRequired',
            class: 'font-weight-bold',
            width: '35%',
            sortable: false,
          },
          {
            text: this.$t('table.headers.28hoursManagementRequired'),
            align: 'center',
            value: '28hoursManagement',
            class: 'font-weight-bold',
            width: '20%',
            sortable: false,
          },
          {
            align: 'center',
            value: 'actions',
            width: '15%',
            sortable: false,
          },
        ],
        items: [],
        snackbarMessage: '',
        snackbar: false,
        snackbarCloseInterval: null,
        options: {},
        editedIndex: -1,
        deleteIndex: -1,
        search: '',
        disableCheckbox: true,
        loading: false,
        totalItems: 0,
        dialog: false,
        dialogDelete: false,
        editedItem: {
          type: '',
          isCheck: false,
          description: '',
          documents: [{ name: '' }],
        },
        defaultItem: {
          type: '',
          isCheck: false,
          description: '',
          documents: [{ name: '' }],
        },
        docsRemoved: [],
        rules: [
          value => !!value.trim() || this.$t('validator.required'),
        ],
      }
    },
    computed: {
      ...get('visa', ['listVisa', 'updatedVisa', 'visaInfo', 'message', 'status', 'error', 'totalCount']),
      formTitle () {
        return this.editedIndex === -1 ? '新規追加' : '編集'
      },
    },
    watch: {
      error (value) {
        this.showSnackBar(value)
      },
      listVisa (value) {
        this.$set(this, 'items', value)
        window.scrollTo(0, document.body.scrollHeight)
      },
      updatedVisa (value) {
        this.$set(this, 'editedItem', value)
      },
      status (value) {
        this.loading = value === 'loading'
      },
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      totalCount (value) {
        this.totalItems = value
      },
    },
    created () {
      if (localStorage.getItem('user')) {
        const userType = JSON.parse(localStorage.getItem('user')).detail.type
        if (userType && userType !== 1) {
          this.disableCheckbox = true
        } else {
          this.disableCheckbox = false
        }
      }
      this.$store.dispatch('visa/getVisa')
    },
    mounted () {
      if (this.$route.query) {
        if (this.$route.query.success) this.showSnackBar('messages.success')
      }
    },
    methods: {
      initialize (search = '') {
        const tmpOptions = { ...this.options }
        tmpOptions.limit = tmpOptions.itemsPerPage
        tmpOptions.offset = (tmpOptions.page - 1) * tmpOptions.limit
        if (search.trim()) {
          tmpOptions.keyword = search.trim()
        }
        delete tmpOptions.page
        delete tmpOptions.groupDesc
        delete tmpOptions.groupBy
        delete tmpOptions.mustSort
        delete tmpOptions.multiSort
        delete tmpOptions.itemsPerPage
        delete tmpOptions.sortDesc
        delete tmpOptions.sortBy
        this.currentOptions = Object.assign({}, tmpOptions)
        this.$store.dispatch('visa/getVisa', tmpOptions)
      },
      showSnackBar (message) {
        if (message) {
          this.snackbarMessage = this.$t(message)
          this.snackbar = true
          if (this.snackbarCloseInterval) {
            clearInterval(this.snackbarCloseInterval)
          }
          this.snackbarCloseInterval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
      onTickCheckbox (item) {
        this.$store.dispatch('visa/updateVisa', {
          id: item.id,
          info: {
            type: item.type,
            isCheck: item.isCheck,
          },
        })
      },
      disableCreateBtn () {
        if (this.editedIndex === -1) {
          if (!this.editedItem.type || !this.editedItem.documents[0].name) {
            return true
          }
          if (!this.editedItem.type.trim() || !this.editedItem.documents[0].name.trim()) {
            return true
          }
        }
        return false
      },
      editItem (item) {
        this.$store.dispatch('visa/getVisaById', { id: item.id })
        this.editedIndex = item.id
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },
      deleteItem (item) {
        this.deleteIndex = item.id
        this.dialogDelete = true
      },
      deleteItemConfirm () {
        this.$store.dispatch('visa/deleteVisa', { id: this.deleteIndex })
        this.closeDelete()
        this.initialize()
        this.initialize()
      },
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      save () {
        if (this.editedIndex > -1) {
          const docsArrDel = this.docsRemoved.length > 0 && this.docsRemoved.map(el => Object.assign(el, el.id ? { isDelete: true } : null))
          const docsArrFull = docsArrDel.length > 0 ? [...this.editedItem.documents, ...docsArrDel] : [...this.editedItem.documents]
          this.$store.dispatch('visa/updateVisa', {
            id: this.editedIndex,
            info: {
              type: this.editedItem.type,
              isCheck: this.editedItem.isCheck,
              description: this.editedItem.description,
              documents: docsArrFull.map(({ id, name, isDelete }) => ({ id, name, isDelete })),
            },
          })
          this.close()
        } else {
          this.$store.dispatch('visa/addVisa', this.editedItem)
          this.close()
        }
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
        this.initialize()
        this.initialize()
        this.initialize()
      },
      addDocumentsRequired () {
        this.editedItem.documents.push({ name: '' })
      },
      removeDocumentsRequired (item, index) {
        const itemRemove = this.editedItem.documents.splice(index, 1)
        if (item.id) {
          this.docsRemoved.push(itemRemove[0])
        }
      },
      getIndex (index) {
        return (this.options.page - 1) * this.options.itemsPerPage + 1 + index
      },
    },
  }
</script>
<style lang="scss" scope>
#dashboard-view {
  background-color: #fff;
  .v-data-table {
    max-width: 100%;
  }
}
.note-symbol {
  color: red;
  font-size: 13px
}
.note-text {
  color: #464E5F;
  font-weight: 500;
}
</style>
